const firebaseErrors = {
  'auth/admin-restricted-operation': 'This operation is restricted to administrators.',
  'auth/argument-error': 'Invalid arguments provided.',
  'auth/cors-unsupported': 'CORS is not supported by your browser.',
  'auth/credential-already-in-use': 'This credential is already associated with another account.',
  'auth/custom-token-mismatch': 'The custom token and API key do not match.',
  'auth/requires-recent-login': 'Please login again, as your last login was too long ago.',
  'auth/email-change-needs-verification': 'Please verify your new email address.',
  'auth/email-already-in-use': 'The email address is already in use.',
  'auth/emulator-config-failed': 'Emulator configuration failed.',
  'auth/expired-action-code': 'The action code has expired.',
  'auth/cancelled-popup-request': 'The popup request has been cancelled.',
  'auth/internal-error': 'An internal error has occurred.',
  'auth/invalid-user-token': 'Invalid user token.',
  'auth/invalid-auth-event': 'Invalid authentication event.',
  'auth/invalid-cert-hash': 'Invalid certificate hash.',
  'auth/invalid-verification-code': 'The provided verification code is invalid.',
  'auth/invalid-custom-token': 'The custom token is invalid.',
  'auth/invalid-dynamic-link-domain': 'The dynamic link domain is invalid.',
  'auth/invalid-email': 'Invalid email address.',
  'auth/invalid-credential': 'Invalid Identity Provider response.',
  'auth/invalid-message-payload': 'Invalid message payload.',
  'auth/invalid-action-code': 'The reset password action code is invalid.',
  'auth/wrong-password': 'Incorrect email or password.',
  'auth/invalid-phone-number': 'Invalid phone number.',
  'auth/invalid-recipient-email': 'Invalid recipient email.',
  'auth/missing-phone-number': 'Missing phone number.',
  'auth/account-exists-with-different-credential': 'An account with this email already exists with a different credential.',
  'auth/network-request-failed': 'A network request has failed.',
  'auth/operation-not-supported-in-this-environment': 'This operation is not supported in your current environment.',
  'auth/popup-blocked': 'Popup blocked by the browser.',
  'auth/popup-closed-by-user': 'Popup closed by user.',
  'auth/provider-already-linked': 'This provider is already linked to your account.',
  'auth/quota-exceeded': 'Quota exceeded.',
  'auth/redirect-cancelled-by-user': 'Redirect cancelled by user.',
  'auth/redirect-operation-pending': 'Redirect operation is pending.',
  'auth/rejected-credential': 'Rejected credential.',
  'auth/second-factor-already-in-use': 'Second factor authentication method already in use.',
  'auth/maximum-second-factor-count-exceeded': 'Maximum number of second factor methods exceeded.',
  'auth/timeout': 'A timeout has occurred.',
  'auth/user-token-expired': 'User token has expired.',
  'auth/too-many-requests': 'Too many attempts. Please try again later.',
  'auth/unverified-email': 'Unverified email address.',
  'auth/user-cancelled': 'User cancelled authentication.',
  'auth/user-not-found': 'There is no user record corresponding to that email. The user may have been deleted.',
  'auth/user-disabled': 'User account is disabled.',
  'auth/user-mismatch': 'User mismatch.',
  'auth/user-signed-out': 'User has signed out.',
  'auth/weak-password': 'The password is weak.',
  'auth/web-storage-unsupported': 'Web storage is unsupported by your browser.',
  'auth/already-initialized': 'Firebase Auth has already been initialized.',
  'auth/invalid-req-type': 'Invalid request type.'
}

export function getErrorMessage (errorCode) {
  return firebaseErrors[errorCode] || 'An unexpected error occurred. Please try again later.'
}
